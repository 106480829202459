import { ProjectInterface } from "../model/ProjectInterface";
import { AppSettings } from "../utils/AppSettings";
import axios, { AxiosResponse } from "axios";

export const fetchProjects = async (): Promise<ProjectInterface[]> => {
  try {
    const response: AxiosResponse<ProjectInterface[]> = await axios.get<ProjectInterface[]>(AppSettings.GITHUB_API_URL, {
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${AppSettings.GITHUB_TOKEN}`
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getProjectLanguages = async (repoName: string)=> {
  try {
    const response = await axios.get(`https://api.github.com/repos/${repoName}/languages`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization" : `Bearer ${AppSettings.GITHUB_TOKEN}`
      },
    });
    return response.data;
  } catch (error) {
    return [];
  }
};