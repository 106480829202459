import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { ProjectInterface } from "../model/ProjectInterface";
import { AppSettings } from "../utils/AppSettings";
import { useTranslation } from "react-i18next";
import { fetchProjects } from "../services/Api";
import GitLanguage from "../components/atom/GitLanguages";

const Projects = () => {
  const { t } = useTranslation();
  const [projects, setProjects] = useState<ProjectInterface[]>([]);

  useEffect(() => {
    fetchProjects().then((projects: ProjectInterface[]) => {
      setProjects(createReadableNameFromRepoName(projects.reverse()));
    });
  }, []);

  const createReadableNameFromRepoName = (
    projects: ProjectInterface[]
  ): ProjectInterface[] => {
    projects.map((item: ProjectInterface) => {
      let name = item.name?.split("-").join(" ");
      item.name = name.charAt(0).toUpperCase() + name.slice(1);
    });
    return projects;
  };

  const projectCard = (project: ProjectInterface) => {
    return (
      <div className="col-11 md:col-4 lg:col-3 flex flex-column border-round-2xl pb-4 pt-2 px-3 bg-white shadow-5">
        <h1 className="text-2xl mb-0">{project.name}</h1>
        <span className="text-xs">{project.full_name}</span>
        <h4 className="mt-0 mb-3 font-alt">{project.subtitle}</h4>
        <p className="font-alt">{project.description}</p>
        <GitLanguage repoName={project.full_name} colorsIntensity={0.7} />
        <div className="mt-5 flex flex-wrap gap-4 justify-content-center">
          {project.demoURL && (
            <Button
              icon={"pi " + AppSettings.GLOBE_ICON}
              onClick={() => (document.location.href = String(project.demoURL))}
              className="p-button-rounded shadow-3 w-full"
              label="Live demo"
              style={{
                background:
                  "linear-gradient(90deg, rgba(249,130,49,1) 0%, rgba(251,161,34,1) 50%)",
                border: "0",
                color: "white",
              }}
            />
          )}
          <Button
            icon={"pi " + AppSettings.CODE_ICON}
            onClick={() => window.open(project.html_url)}
            className="p-button-rounded text-color w-full"
            outlined
            label="View on GitHub"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-column justify-content-center w-full h-full">
      <h1 className="text-5xl uppercase text-center">
        {t(`projects.header` as const)}
      </h1>
      <hr className="mb-6 w-3" />
      <div className="grid gap-4 flex justify-content-center">
        {projects.map((item) => projectCard(item))}
      </div>
    </div>
  );
};

export default Projects;
