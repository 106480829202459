import React, { useEffect, useState } from "react";
import * as gitLangColors from "./github-lang-colors.json";
import { getProjectLanguages } from "../../services/Api";

interface GitLanguageProps {
  repoName: string,
  colorsIntensity: number
}

const GitLanguage = (props: GitLanguageProps
) => {
  const [values, setValues] = useState<any>();
  const [data, setData] = useState<any[]>();

  const setColor = (langName: string) => {
    for (var prop in gitLangColors) {
      if (prop == langName) {
        return gitLangColors[langName as keyof typeof gitLangColors];
      }
    }
  };

  useEffect(() => {
    getProjectLanguages(props.repoName).then((response) => setValues(response));
  }, []);

  useEffect(() => {
    let array: any[] = [];
    for (var prop in values) {
      let lang = {
        name: "",
        amount: 0,
      };
      lang.name = prop;
      lang.amount = values[prop];
      array.push(lang);
    }
    setData(array);
  }, [values]);

  const round = (value: number, precision: number) => {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const calculatePercentage = (value: number) => {
    let sum = 0;
    if (data) {
      for (let i = 0; i < data.length; i++) {
        sum += data[i].amount;
      }
    }
    return round((value / sum) * 100, 1);
  };

  return (
    <div className="flex flex-column border-round-xl">
      <span className="text-left mb-3 font-bold">Languages</span>
      <div className="w-full flex" style={{ height: "8px", gap: "2px" }}>
        {data &&
          data.map((item, index) => (
            <div
              style={{
                width: `${item.amount}%`,
                backgroundColor: `${setColor(item.name)}`,
                opacity: `${props.colorsIntensity}`,
                borderTopLeftRadius: index === 0 ? "10px" : "0",
                borderBottomLeftRadius: index === 0 ? "10px" : "0",
                borderTopRightRadius: index === data.length - 1 ? "10px" : "0",
                borderBottomRightRadius:
                  index === data.length - 1 ? "10px" : "0",
              }}
            ></div>
          ))}
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {data?.map((item) => (
          <div className="flex align-items-center gap-2">
            <div
              className="border-circle"
              style={{
                height: "8px",
                width: "8px",
                backgroundColor: `${setColor(item.name)}`,
                opacity: `${props.colorsIntensity}`,
              }}
            ></div>
            <div className="flex gap-2 align-items-center">
              <span>{item.name}</span>
              <span className="text-xs text-color-secondary">{`${calculatePercentage(
                item.amount
              )}%`}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GitLanguage;
